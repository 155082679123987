<template>
  <component :is="currentTab" :exprReqPre="exprReqPre" :userId="userId" :categories="categories"
    :filteredCategories="filteredCategories" :selectedMonth="selectedMonth"
    @monthChanged="(month) => (selectedMonth = month)" @newArticleAdded="getCategories">
  </component>
</template>

<script>
import AccountSettings from "./LoggedIn/AccountSettings.vue";
import AddArticle from "./LoggedIn/AddArticle.vue";
import EditArticles from "./LoggedIn/EditArticles.vue";
import ArticlesOverview from "./LoggedIn/ArticlesOverview.vue";
import axios from "axios";

export default {
  components: {
    account: AccountSettings,
    add: AddArticle,
    edit: EditArticles,
    overview: ArticlesOverview,
  },

  data() {
    return {
      categories: [],
      categoriesLoaded: false,
      ignoredCategories: [],
      ignoredCategoriesLoaded: false,
      filteredCategories: [],
      selectedMonth: undefined, // used to store value between change of tabs
    };
  },

  methods: {
    getCategories: function () {
      axios
        .get(`${this.exprReqPre}getCategories`, { withCredentials: true })
        .then((ret) => {
          this.categories = ret.data;
          this.categoriesLoaded = true;
        });
    },
    getIgnoredCategories: function () {
      axios
        .get(`${this.exprReqPre}getIgnoredCategories`, { withCredentials: true })
        .then((ret) => {
          this.ignoredCategories = ret.data;
          this.ignoredCategoriesLoaded = true;
        });
    },
    filterCategories() {
      if (this.categoriesLoaded && this.ignoredCategoriesLoaded) {
        this.filteredCategories = this.categories.filter((cat) => !this.ignoredCategories.includes(cat));
      }
    }
  },

  props: ["userId", "currentTab", "exprReqPre"],

  computed: {
    userName: () => this.userId,
    currentMonth: function () {
      let today = new Date();
      return new Date(today.getFullYear(), today.getMonth(), 1);
    }
  },

  mounted: function () {
    this.selectedMonth = this.currentMonth;
    this.getCategories();
    this.getIgnoredCategories();
  },

  watch: {
    currentTab: function () {
      this.getCategories();
      this.getIgnoredCategories();
    },
    categories: function () { this.filterCategories() },
    ignoredCategories: function () { this.filterCategories() },
  },
};
</script>

<style></style>