<template>
  <h2 class="display-6">Account Settings</h2>
  <h2 class="display-8">Select categories to be shown</h2>

  <div class="row">
    <div class="col-12 col-lg col-xl-4">
      <div>
        <div class="row py-1 text-nowrap text-right cat-list" v-for="(obj, index) in shownCategories" :key="index">
          <span class="col-6">{{ obj.category }}</span>
          <span class="col-6">
            <input type="checkbox" v-model="obj.show" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {

  },

  data() {
    return {
      categories: [],
      shownCategories: [],
      msg: "",
      err: false,
    };
  },

  methods: {
    getCategories: function () {
      axios
        .get(`${this.exprReqPre}getCategories`, { withCredentials: true })
        .then((ret) => {
          this.categories = ret.data;
          this.getIgnoredCategories();
        });
    },
    getIgnoredCategories: function () {
      axios
        .get(`${this.exprReqPre}getIgnoredCategories`, { withCredentials: true })
        .then((ret) => {
          let ignoredCategories = ret.data;
          this.shownCategories = this.categories
            .map((val) => {
              let ret = {
                category: val,
                show: !ignoredCategories.includes(val)
              }
              return ret;
            });
        });
    }
  },

  watch: {
    shownCategories: {
      handler() {
        let ignoredCategories = this.shownCategories
          .filter((obj) => obj.show == false)
          .map((obj) => obj.category);

        axios
          .post(`${this.exprReqPre}setIgnoredCategories`, {
            ignoredCategories: ignoredCategories
          }, { withCredentials: true })
          .then(() => {
          })
      },
      deep: true
    }
  },

  props: ["userId", "exprReqPre"],

  mounted: function () {
    this.getCategories();
  },
};
</script>

<style>
.cat-list:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>